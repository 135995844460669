<template>
    <a-card :bordered="false">
      <a-input-search
        placeholder="请输入资料标题"
        style="display:block;  width:50%;"
        v-model="searchParam.title"
        enter-button @search="onSearch" />
      <a-table
        :columns="column"
        :dataSource="menuDetailList"
        :pagination="pagination"
        rowKey="id"
        @change="handleTableChange"
        :customRow="handleClickRow"
      >

<!--        <template slot="action" slot-scope="text, record">-->
<!--          <a @click="viewItem(record)">查看</a>-->
<!--        </template>-->
      </a-table>
    </a-card>
</template>

<script>
import { getDocList } from '@/api/basic/common'

export default {
  name: 'TableList',
  components: {
  },
  data () {
    return {
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '15', '20'],
        showTotal: total => `共有 ${total} 条数据`
      },
      nowPage: 1,
      viewTab: true,
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      menuParam: {
        menu: '资料类型'
      },
      searchParam: {
        title: '',
        type: 0
      },
      // 接口返回数据
      menuList: [],
      menuSelect: '1',
      menuDetailList: [],
      // table
      column: [
        {
          title: '#',
          dataIndex: 'index',
          key: 'index',
          width: '10%'
        },
        {
          title: '资料标题',
          dataIndex: 'title',
          key: 'name',
          width: '70%',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '资料类型',
          dataIndex: 'typeName',
          key: 'typeName',
          width: '20%',
          scopedSlots: { customRender: 'typeName' }
        }
      ]
    }
  },
  filters: {},
  created () {

    if(this.$route.query.id!=null)
    {
      this.searchParam.type = this.$route.query.id
    }

    this.getList()
  },
  computed: {},
  methods: {
    // 接口请求
    getList () {
      getDocList(this.searchParam).then(response => {
        for (var i = 0;i<response.data.list.length;i++) {
          response.data.list[i].index = i + 1
        }
        this.menuDetailList = response.data.list
      })
    },
    handleClickRow(record, index){
      return {
        on: {
          click: () => {
            this.viewItem (record)
          }
        }
      }
    },
    viewItem (record) {
      this.$router.push({
        path: '/base/doc/view',
        query: {
          id: record.id
        }
      })
    },
    tableChange (value, col, i) {
      this.menuDetailList[i + (this.nowPage - 1) * this.pagination.pageSize][col] = value
    },
    onSearch (value) {
      // console.log(value);
      this.searchParam.title = value
      this.getList()
    },
    handleTableChange (pagination) {
      // console.log(pagination)
      this.nowPage = pagination.current
    },
    showTab (v) {
      this.viewTab = v
    }
  }
}
</script>
